import React from 'react';

const LandingPage = () => {
  return (
    <div className="bg-gray-100 text-gray-900 min-h-screen">
      {/* Top Section with Company Name */}
      <header className="bg-blue-900 py-6 text-center text-white">
        <p className="text-sm font-light tracking-wide uppercase">10xdevelopment.co</p> {/* Company name in small text */}
        <h1 className="text-6xl font-bold mt-2">Modernize and Scale Your Business with Tailored Cloud Solutions</h1>
        <p className="mt-4 text-lg">
          Harness the power of cloud computing to optimize, modernize, and future-proof your business infrastructure.
        </p>
        <a
          href="#services"
          className="mt-8 inline-block bg-yellow-400 text-black px-8 py-4 rounded-full font-bold text-lg hover:bg-yellow-500 transition"
        >
          Explore Our Services
        </a>
      </header>

      {/* Introduction Section */}
      <section className="py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold">Cloud Infrastructure & Modernization Solutions</h2>
          <p className="text-lg mt-4">
            At 10xdevelopment.co, we specialize in providing cloud infrastructure and modernization solutions that help businesses transition smoothly into the digital age. Whether you’re migrating from legacy systems or building cloud-native applications from scratch, our tailored services fit your unique business needs.
          </p>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section id="services" className="py-20 bg-gray-200">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold">Why Choose Us?</h2>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-10">
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <h3 className="text-2xl font-bold mb-2">End-to-End Cloud Solutions</h3>
              <p className="text-md">We handle every stage of your cloud journey, from strategy to deployment and ongoing management.</p>
            </div>
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <h3 className="text-2xl font-bold mb-2">Custom Cloud Solutions</h3>
              <p className="text-md">Our tailored solutions are crafted to meet your unique business needs and help you scale effectively.</p>
            </div>
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <h3 className="text-2xl font-bold mb-2">Security & Compliance</h3>
              <p className="text-md">We ensure your infrastructure meets all security and compliance standards, from GDPR to HIPAA.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Our Process Section */}
      <section className="py-20">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-12">
          <div className="text-left">
            <h2 className="text-4xl font-bold text-blue-900">Our Process</h2>
            <ul className="list-disc mt-4 ml-5 text-lg">
              <li className="mb-2">Cloud Readiness Assessment</li>
              <li className="mb-2">Cloud Strategy & Planning</li>
              <li className="mb-2">Cloud Migration</li>
              <li className="mb-2">Optimization & Management</li>
              <li className="mb-2">Support & Monitoring</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="bg-yellow-400 py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold text-gray-900">Ready to Modernize?</h2>
          <p className="mt-4 text-lg text-gray-800">Contact us today and take the first step in modernizing your cloud infrastructure.</p>
          <a
            href="#contact"
            className="mt-8 inline-block bg-blue-900 text-white px-8 py-4 rounded-full font-bold text-lg hover:bg-blue-700 transition"
          >
            Get Started
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 py-6 text-white text-center">
        <p>© 2024 10xdevelopment.co. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
